@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;

.info-tabs {
  position: relative;
  display: flex;
  flex-direction: column;

  &__content {
    img {
      border-radius: 12px;
    }
  }

  :global(.block__title) {
    @include mixins.container-base;
  }

  :global(.swiper-container--info-tabs) {
    @include mixins.container-base;

    :global(.swiper) {
      position: relative;
      width: 100%;
      height: 100%;

      :global(.swiper-slide) {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        height: max-content;
        cursor: grab;
      }
    }

    :global(.swiper-nav) {
      display: none;

      @include mixins.xs {
        display: flex;
      }
    }
  }

  :global(.swiper.swiper-tabs) {
    @include mixins.clamp(padding-right, 20px, 40px);
    @include mixins.clamp(padding-left, 20px, 40px);

    @include mixins.xs {
      padding: 5px;
    }

    & + :global(.swiper-container--info-tabs) {
      @include mixins.clamp(margin-top, 24px, 32px);
    }
  }

  .box-shadow {
    :global(.swiper) {
      width: calc(100% + 10px);
      padding: 5px;
      margin: -5px;

      :global(.swiper-slide) {
        border-radius: 12px;
        padding: 16px;
        padding: utilities.get-clamp(16px, 40px) utilities.get-clamp(16px, 48px);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
          0px 0px 4px rgba(0, 0, 0, 0.06);
      }
    }
  }
}
