.accelerator {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  line-height: 15.83px;

  img {
    width: auto;
    height: 16px;
  }

  &.accelerator-best-price,
  &.discount {
    color: var(--success-main);
  }

  &.accelerator-custom-text {
    color: var(--warning-main);
  }

  &.promocode-accelerate {
    color: #831797;
  }
}
