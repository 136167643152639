@use "@/styles/mixins" as mixins;

.benefits {
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mixins.clamp(gap, 15px, 28px);
  }

  &__title {
    font-size: 22px;
    line-height: 27px;
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    background: #fbfbfd;
    border-radius: 12px;
    padding: 20px;
    min-width: 152px;

    &--box-shadow {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
        0px 0px 4px rgba(0, 0, 0, 0.06);
    }

    &:last-of-type {
      border-right: none;
    }

    img {
      width: auto;
      @include mixins.clamp(height, 46px, 56px);

      & + * {
        margin-top: 23px;
      }
    }

    &__name {
      font-weight: 700;
      @include mixins.clamp(font-size, 15px, 18px);
      @include mixins.clamp(line-height, 18px, 22px);

      & + * {
        margin-top: 8px;
      }
    }

    &__description {
      text-align: center;
      @include mixins.clamp(font-size, 14px, 16px);
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 375px) {
  .benefits {
    &__benefit {
      max-width: unset;
      width: 100%;
    }
  }
}
