@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;
@use "@/components/common/Slider/Slider.module.scss" as slider;

.modal-portal {
  position: fixed;
  inset: 0;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  z-index: 1101;
}

.modal {
  position: relative;
  width: calc(100% - #{utilities.get-clamp(40px, 100px)});
  max-width: 875px;
  min-width: 300px;
  height: 540px;
  max-height: 600px;
  padding: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 12px;
  z-index: 1102;
  overflow: hidden;

  &--full-width {
    max-width: 1200px;
    height: 45.6vw;
  }

  &--fullscreen {
    width: unset;
    min-width: unset;
    max-width: unset;
    height: unset;
    min-height: unset;
    max-height: unset;
    background-color: unset;

    :global(.swiper-slide) {
      img,
      video,
      picture {
        max-width: 100%;
        height: auto;
        border-radius: 12px;
      }
    }

    .modal__close-button {
      @include slider.swiper-circle-controls();

      position: fixed;
      @include mixins.clamp(top, 24px, 70px);
      @include mixins.clamp(right, 24px, 70px);
      z-index: 1103;

      @include mixins.rtl {
        right: unset;
        @include mixins.clamp(left, 24px, 70px);
      }
    }
  }

  @include mixins.max-sm {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;

    :global(.swiper-slide) {
      & > img,
      & > video,
      & > picture {
        border-radius: 0;
      }
    }
  }
}

.modal__close-button {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 13px;
  z-index: 1102;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;

  svg {
    display: block;
  }

  &:hover {
    svg path.need-fill {
      fill: var(--main-color);
    }

    svg path.need-stroke {
      stroke: var(--main-color);
    }
  }
}
