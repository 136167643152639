.post-list {
  &__items {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  &--other {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 100%;
  }

  &--single {
    .post {
      max-width: 100%;
      flex-direction: row;
      gap: 24px;

      & > a {
        flex-basis: 65.65%;

        img {
          aspect-ratio: 193/54;
        }
      }

      &__post-info {
        flex-basis: 34.35%;
      }
    }
  }

  &--featured {
    display: flex;
    flex-basis: 65.65%;
    flex-direction: column;
    gap: 32px;
    height: max-content;

    .post {
      max-width: unset;
      width: 100%;

      & > a img {
        aspect-ratio: 386 / 165;
      }
    }
  }

  &--tiles {
    .post-list--other {
      flex-direction: column;
      flex-basis: 34.35%;

      .post {
        min-width: unset;
        width: 100%;
      }
    }
  }
}

.post {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 375px;
  height: max-content;
  gap: 16px;

  & > a {
    &:hover + .post__post-info .post__post-title {
      color: var(--success-main);
    }

    img {
      width: 100%;
      aspect-ratio: 187/108;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }
  }

  &__post-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__post-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    &:hover {
      color: var(--success-main);
    }
  }

  &__post-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    // text-overflow: ellipsis;
    // max-height: 51px;
    // overflow: hidden;

    &,
    * {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

@media screen and (max-width: 576px) {
  .post-list {
    &__items {
      flex-direction: column;
    }

    &--other {
      flex-direction: column;
    }

    &--single {
      .post {
        flex-direction: column;
      }
    }
  }

  .post {
    width: 100%;
    min-width: unset;

    & > a img {
      aspect-ratio: 140 / 81 !important;
    }
  }
}
