.event__score {
  height: max-content;
  background: var(--success-main);
  border-radius: 4.72607px;
  font-weight: 700;
  font-size: 16.5413px;
  line-height: 20px;
  padding: 2px 0;
  color: #fff;
  margin-left: 7px;
  min-width: 36px;
  text-align: center;
}
