.image_gallery {
  :global(.swiper-slide) {
    border-radius: 8px;
    overflow: hidden;

    video,
    img {
      border-radius: inherit;
    }
  }
}
