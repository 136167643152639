@use "@/styles/mixins" as mixins;

.toggle-view {
  display: inline-grid;
  grid-template-columns: repeat(var(--options-count, 1), 1fr);
  align-items: center;
  padding: 4px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    height: calc(100% - 8px);
    width: calc((100% - 4px) / var(--options-count, 1));
    background: #fff;
    border-radius: 50px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06),
      0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    transform: translateX(calc((100% - 4px) * var(--active-index, 0)));
    transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;
    z-index: 0;
  }

  .toggle-view__option {
    padding: 7px 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    z-index: 1;
    text-align: center;

    @include mixins.clamp(font-size, 12px, 14px);
    @include mixins.clamp(line-height, 16px, 18px);
  }
}
