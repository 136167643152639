@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;

.hero {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mixins.clamp(height, 400px, 792px);
  // max-width: 100vw;
  // width: 100vw;
  margin-top: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 23, 0.1) 36.98%,
    rgba(0, 0, 35, 0) 100%
  );
  background-size: 100% 120px;
  background-size: 100% utilities.get-clamp(120px, 200px);
  background-repeat: no-repeat;

  & + :global(.container) {
    margin-top: -32px;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 32px;
    border-radius: 32px 32px 0 0;
    background-color: #ffffff;
    z-index: 1;
  }

  picture {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .hero__image,
  .hero__video {
    width: 100%;
    max-width: 100vw;
    height: inherit;
    object-fit: cover;
    object-position: center;
  }

  .hero__video {
    position: absolute;
    z-index: 1;
  }

  :global(.container) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mixins.clamp(gap, 20px, 40px);
    @include mixins.clamp(padding-top, 80px, 120px);
    @include mixins.clamp(padding-bottom, 28px, 120px);
    z-index: 2;

    :global(.block__title) {
      max-width: 720px;
      font-weight: 700;
      @include mixins.clamp(font-size, 42px, 96px);
      @include mixins.clamp(line-height, 51px, 100px);
      color: #ffffff;
      margin: 0;
    }

    :global(.button-list) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 24px;
      flex: 1;
      width: auto;
      margin: 25px 0 20px;

      :global(.button) {
        width: 100%;
        max-width: 280px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .hero {
    background-image: linear-gradient(
      180deg,
      #000000b3 0%,
      rgba(0, 0, 0, 0) 100%
    );

    :global(.container) {
      // flex-direction: column;
      // justify-content: center;
      // align-items: start;

      :global(.block__title),
      :global(.button-list) {
        flex: unset;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .hero {
    :global(.container) {
      :global(.button-list) {
        margin: 0;
      }
    }
  }
}
