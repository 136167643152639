.event__tags {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-secondary);

  &__tag {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 6px;

    &:last-of-type {
      margin: 0 0 0 6px;
    }

    &:first-of-type {
      margin: 0 6px 0 0;
    }

    & + &:before {
      content: "";
      position: absolute;
      left: -8px;
      background-color: var(--text-disabled);
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }
  }
}
