@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;

.header {
  position: fixed;
  z-index: 1060;
  padding: 22.5px 0px;
  padding: utilities.get-clamp(22.5px, 32px) 0;
  width: 100%;
  transition: all 0.2s linear;

  :global(.dropdown__toggle) {
    color: white;
  }

  &.is-stickied {
    padding: 10px 0px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);

    :global(.dropdown__toggle) {
      color: var(--text-primary);

      &:hover {
        color: var(--primary-main);
      }
    }
  }

  .logo {
    width: auto;
    @include mixins.clamp(height, 37.5px, 48px);
  }

  :global(.container) {
    display: flex;
    justify-content: space-between;
  }

  .switchers {
    display: flex;
    align-items: center;
    @include mixins.clamp(gap, 24px, 30px);
    color: #fff;

    :global(.dropdown--languages),
    :global(.dropdown--currencies) {
      :global(.dropdown__toggle span) {
        font-size: 13px;
        line-height: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include mixins.clamp(max-width, 40px, 700px);
      }
    }
  }
}
