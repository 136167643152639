@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.dropdown-container {
  position: relative;
}

.dropdown {
  position: fixed;
  min-width: 150px;
  width: auto;
  z-index: 1;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 10px;

  &.visible {
    visibility: visible;
    opacity: 1;
    animation: fadeIn 0.2s ease-in-out;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    animation: fadeOut 0.2s ease-in-out;
  }
}

.dropdown__arrow {
  transition: transform 0.2s ease-in-out;
}

.dropdown__toggle {
  cursor: pointer;

  &:hover {
    color: var(--primary-main);
  }

  .dropdown__arrow {
    transform: rotate(0);
  }

  &.visible .dropdown__arrow {
    transform: rotate(-180deg);
  }
}

.dropdown__content {
  overflow: hidden;
  min-width: 150px;
}

.dropdown__item {
  padding: 8px;
  color: var(--text-primary);
  text-decoration: none;
  display: block;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &--is-active {
    color: var(--primary-main);
    background-color: rgba(55, 167, 248, 0.08);
  }
}

.dropdown__button {
  width: 100%;
  padding: 0;
  background: #ffffff;
  border: none;
  text-align: left;
}
