@use "@/styles/utilities" as utilities;
@use "@/styles/mixins" as mixins;

.event {
  position: relative;
}

.event__name {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 4px;

  a {
    @include mixins.clamp(font-size, 20px, 24px);
    @include mixins.clamp(line-height, 24px, 29.23px);
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:hover {
      color: unset;
    }
  }

  &:hover {
    a {
      color: var(--success-main);
    }

    svg {
      path {
        fill: var(--success-main);
      }
    }
  }
}

.buy-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  .price-from {
    white-space: nowrap;
  }

  .buy-block__info {
    .price-actual {
      font-size: 26px;
      line-height: 30px;
    }
  }

  .buy-block__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: auto;

    :global(.go-to) {
      padding: 18px 25px;
    }

    :global(.button) {
      width: 100%;
    }
  }

  @include mixins.md {
    flex-direction: row;

    .buy-block__actions {
      :global(.button) {
        width: unset;
      }
    }
  }
}

.event--default {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  @include mixins.clamp(gap, 20px, 32px);
  @include mixins.clamp(padding, 16px, 24px);

  :global(.event__media),
  .event__info {
    width: 100%;
  }

  .event__info {
    display: flex;
    flex-direction: column;
    padding: 8px;
    overflow: hidden;

    .event__name {
      padding-bottom: 16px;
    }

    :global(.event__excerpt),
    :global(.event__popularity) {
      margin-bottom: 16px;
    }

    :global(.event__popularity) {
      padding-top: 16px;
      border-top: 1px solid #eee;
    }

    :global(.event__excerpt) *,
    :global(.compare-field) * {
      font-size: 16px;
      line-height: 20px;
    }

    :global(.compare-field__title) {
      display: none;
    }

    :global(.event__tags) {
      margin-bottom: 12px;
    }

    .buy-block {
      padding-top: 16px;
      border-top: 1px solid var(--text-hint);
      box-shadow: 0px -8px 16px rgba(255, 255, 255, 1);
      z-index: 1;
    }
  }

  @include mixins.sm {
    flex-direction: row;

    :global(.event__media),
    .event__info {
      width: 50%;
    }
  }
}

.event--compact {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: calc(50% - 16px);
  box-shadow: none;
  gap: 12px;
  padding: 0;
  width: 100%;

  .accelerators {
    position: absolute;
    top: 12px;
    left: 12px;
    display: flex;
    gap: 6px;
    z-index: 2;

    :global(.accelerator) {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #fff;
      padding: 6px 10px;
      border-radius: 28px;

      &:global(.accelerator-count-favorite) {
        background-color: var(--error-main);

        svg {
          path {
            fill: #fff;
          }
        }
      }

      &:global(.accelerator-user-views) {
        background-color: #242424;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  :global(.swiper-container--gallery) {
    width: 100%;
  }

  :global(.event__media),
  .event__info {
    width: 100%;
  }

  .event__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    padding: 0;

    .event__name {
      a {
        font-size: 20px;
        line-height: 24.36px;
      }

      svg {
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .buy-block {
      border-top: none;
      padding-top: 0;

      .buy-block__info {
        :global(.event__price) {
          gap: 2px;
        }
      }

      .buy-block__actions {
        flex-direction: row-reverse;

        :global(.button) {
          padding: 11px 18px;
        }

        :global(.go-to) {
          padding: 12px 18px;
        }
      }
    }
  }

  @include mixins.max-sm {
    max-width: 100%;
    flex-direction: row !important;
    gap: 18px;
    scroll-snap-align: start;

    &:not(:global(.last-column)) {
      @include mixins.clamp(padding-right, 20px, 40px);
    }

    :global(.event__media) {
      width: 100px !important;
      height: 100px;

      img {
        aspect-ratio: 1 / 1;
      }
    }

    .event__info {
      gap: 4px;

      .event__name {
        a {
          font-size: 16px;
          line-height: 20px;
        }
      }

      :global(.accelerator) {
        order: 3;
      }

      :global(.buy-block) {
        margin-top: 0;

        .buy-block__info {
          :global(.event__price) {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 6px;

            * {
              margin-top: 0 !important;
              font-size: 14px;
              line-height: 17px;
            }

            :global(.event__price-label) {
              display: none;
            }

            :global(.event__price-final-amount) {
              margin-top: 0 !important;
            }
          }
        }

        .buy-block__actions {
          display: none;
        }
      }
    }

    :global(.swiper-container--gallery) {
      height: 100%;

      :global(.swiper-slide) {
        img {
          aspect-ratio: 1 / 1;
        }
      }

      :global(.swiper-nav),
      :global(.swiper-pagination) {
        display: none;
      }
    }

    .accelerators {
      display: none;
    }
  }

  @include mixins.md {
    max-width: calc(33.333% - 16px);
  }
}

.event--compare {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  & > div {
    overflow: unset;
  }

  :global(.event__media),
  .event__info,
  :global(.swiper-container--gallery) {
    width: 100%;
  }

  .event__info {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    padding: 0 8px 8px;

    .event__name,
    :global(.event__popularity),
    :global(.compare-field:not(.compare-field--last)) {
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
    }

    :global(.event__popularity) {
      flex-direction: column;

      :global(.accelerator-count-favorite) {
        order: 1;
      }

      :global(.event__people) {
        order: 2;
      }

      :global(.accelerator-user-views) {
        order: 3;
      }
    }

    .event__name {
      a {
        height: 44px;
        height: utilities.get-clamp(44px, 48px);
        font-size: 18px;
        font-size: utilities.get-clamp(18px, 20px);
        line-height: 22px;
        line-height: utilities.get-clamp(22px, 24px);
        font-weight: 400;
      }
    }

    :global(.compare-field *) {
      font-size: 14px;
      line-height: 18px;
    }

    .buy-block {
      flex-direction: column;
      padding-top: 12px;
      border-top: 1px solid var(--text-hint);

      .buy-block__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      .buy-block__info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        :global(.event__price) {
          gap: 8px;
        }
      }

      .buy-block__actions {
        :global(.button) {
          width: 100%;
        }
      }
    }
  }
}

.event--in-modal {
  gap: 0;
  height: 100%;
  padding: 0;

  :global(.event__media) {
    height: 100%;

    :global(.swiper-container--gallery .swiper),
    :global(.swiper-slide) img,
    img {
      aspect-ratio: 1 / 1;
      border-radius: 0;
    }
  }

  .event__info {
    flex: 1;
    @include mixins.clamp(padding, 20px, 32px);

    :global(.swiper-container) {
      flex: 1;
    }
  }

  @include mixins.max-sm {
    flex-direction: column;

    :global(.event__media),
    .event__info {
      width: 100%;
    }

    :global(.event__media) {
      max-height: 50vw;

      :global(.swiper-container--gallery .swiper),
      :global(.swiper-slide) img,
      img {
        aspect-ratio: 16 / 9;
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  .event--in-modal {
    flex-direction: row;

    :global(.event__media),
    .event__info {
      width: 50%;
    }

    :global(.event__media) {
      max-height: unset;
    }
  }
}
