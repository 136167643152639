.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5.6px;
  height: max-content;
  padding: 15px 53px;
  text-align: center;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid transparent;
  white-space: nowrap;
  cursor: pointer;

  & > img,
  & > svg {
    height: 23.33px;
    width: auto;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: #ffffff;
    // width: 100%;
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
  }

  &__text--additional {
    font-size: 9px;
    line-height: 9px;

    & + .button__text {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &--disabled,
  &:disabled {
    cursor: not-allowed;
  }

  &-success,
  &--success {
    background-color: var(--success-main);

    &--disabled,
    &:disabled {
      background-color: var(--success-dark);

      &:hover {
        .button__text,
        .button__text--additional {
          color: #ffffff;
        }
      }
    }

    &:not(.button--disabled, :disabled):hover {
      background-color: var(--success-dark);
      border-color: var(--success-dark);
    }
  }

  &--outline-success {
    background-color: transparent;
    border: 2px solid var(--success-main);

    .button__text,
    .button__text--additional {
      color: var(--success-main);
    }

    &:hover {
      background-color: var(--success-dark);

      .button__text,
      .button__text--additional {
        color: #fff;
      }
    }
  }

  &--primary {
    background-color: var(--primary-main);

    &--disabled,
    &:disabled {
      background-color: var(--primary-dark);

      &:hover {
        .button__text,
        .button__text--additional {
          color: #fff;
        }
      }
    }

    &:not(.button--disabled, :disabled):hover {
      background-color: var(--primary-dark);
      border-color: var(--primary-dark);
    }
  }

  &--light {
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);

    .button__text,
    .button__text--additional {
      color: var(--text-primary);
    }

    &:not(.button--disabled, :disabled):hover {
      .button__text,
      .button__text--additional {
        color: var(--primary-main);
      }
    }
  }
}
