@use "@/styles/mixins" as mixins;

.accordion-item {
  border-bottom: 1px solid var(--text-disabled);

  &:first-of-type {
    border-top: 1px solid var(--text-disabled);
  }

  &--expanded {
    .accordion-summary {
      svg {
        transform: rotate(-180deg);
      }
    }

    .accordion-wrapper {
      grid-template-rows: 1fr;
      margin-top: -10px;
      @include mixins.clamp(padding-bottom, 16px, 24px);
    }
  }
}

.accordion-summary {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  cursor: pointer;

  @include mixins.clamp(padding-top, 16px, 24px);
  @include mixins.clamp(padding-bottom, 16px, 24px);

  svg {
    min-width: 14px;
    max-width: 14px;
    transition: transform 0.2s ease-in-out;
  }
}

.accordion-title {
  @include mixins.clamp(font-size, 18px, 22px);
  user-select: none;
}

.accordion-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: all 0.2s;
}

.accordion-details {
  padding-right: 24px;
  overflow: hidden;
  box-sizing: content-box;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include mixins.rtl {
    padding-right: unset;
    padding-left: 24px;
  }
}
