@use "@/styles/utilities" as utilities;
@use "@/styles/variables" as variables;
@use "sass:map";

@mixin rtl {
  [dir="rtl"] & {
    @content;
  }
}

@mixin clamp($property, $min, $max) {
  #{$property}: $min;
  #{$property}: utilities.get-clamp($min, $max);
}

@mixin xs {
  @media (min-width: map.get(variables.$breakpoints, xs)) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map.get(variables.$breakpoints, sm)) {
    @content;
  }
}

@mixin md {
  @media (min-width: map.get(variables.$breakpoints, md)) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map.get(variables.$breakpoints, lg)) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map.get(variables.$breakpoints, xl)) {
    @content;
  }
}

@mixin max-xs {
  @media (max-width: calc(map.get(variables.$breakpoints, xs) - 1px)) {
    @content;
  }
}

@mixin max-sm {
  @media (max-width: calc(map.get(variables.$breakpoints, sm) - 1px)) {
    @content;
  }
}

@mixin max-md {
  @media (max-width: calc(map.get(variables.$breakpoints, md) - 1px)) {
    @content;
  }
}

@mixin max-lg {
  @media (max-width: calc(map.get(variables.$breakpoints, lg) - 1px)) {
    @content;
  }
}

@mixin max-xl {
  @media (max-width: calc(map.get(variables.$breakpoints, xl) - 1px)) {
    @content;
  }
}

@mixin full-width-classes {
  @each $name, $size in variables.$breakpoints {
    .full-width-#{$name} {
      @media (min-width: $size) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

@mixin container-base {
  width: 100%;
  max-width: calc(100vw - calc(utilities.get-clamp(20px, 40px) * 2));
  margin-right: auto;
  margin-left: auto;
}

@mixin display-utilities {
  $display-types: (
    none: none,
    block: block,
    inline: inline,
    inline-block: inline-block,
    flex: flex,
    grid: grid,
  );

  @each $name, $value in $display-types {
    .d-#{$name} {
      display: $value !important;
    }
  }

  @each $name, $size in variables.$breakpoints {
    @if $name != xs {
      @each $disp-name, $disp-value in $display-types {
        .d-#{$name}-#{$disp-name} {
          @media (min-width: #{$size}) {
            display: $disp-value !important;
          }
        }
      }
    }
  }
}
