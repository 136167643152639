.event__price {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &-discount-label {
    font-size: 13px;
    line-height: 15.83px;
    color: var(--text-secondary);
  }

  &-original-amount {
    &--crossed {
      text-decoration: line-through;
    }
  }

  &-final-amount {
    font-size: 26px;
    line-height: 30px;
  }
}
