@use "@/styles/utilities" as utilities;
@use "@/styles/mixins" as mixins;

.pov {
  :global(.swiper-slide) {
    @include mixins.clamp(width, 280px, 186px);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
  }

  .svg-union {
    position: absolute;
    top: 15px;
    right: 15px;

    @include mixins.clamp(width, 34px, 28px);
    @include mixins.clamp(height, 22px, 18px);

    @include mixins.rtl {
      right: unset;
      left: 15px;
    }
  }

  :global(.swiper) {
    padding-right: utilities.get-clamp(20px, 40px);
    padding-left: utilities.get-clamp(20px, 40px);

    @include mixins.sm {
      padding: 0px;
    }

    &:not(:global(.swiper-initialized)) {
      :global(.swiper-slide) {
        margin-right: 10px;
      }
    }
  }
}

.pov-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  padding: 20px;
  padding: utilities.get-clamp(20px, 32px);
}

.pov-media {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;

  :global(.image-placeholder),
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.pov__username {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary);

  svg {
    min-width: 22px;
    max-width: 22px;
    min-height: 22px;
    max-height: 22px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--default {
    position: absolute !important;
    padding: 15px;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    color: #ffffff !important;
    z-index: 3;
  }

  &--modal {
    span {
      font-size: 20px;
      line-height: 18px;
    }
  }
}

a.pov__username:hover {
  color: var(--primary-main) !important;
}

.swiper-container--pov-modal {
  display: flex;
  align-items: center;
  height: inherit;

  :global(.swiper-slide) {
    display: flex;
    flex-direction: row;
  }

  .svg-union {
    position: absolute;
    top: 15px;
    left: 15px;
    @include mixins.clamp(width, 34px, 28px);
    @include mixins.clamp(height, 22px, 18px);
  }

  .pov-info,
  .pov-media {
    width: 50%;
  }

  img,
  video {
    height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 43 / 54;
  }

  :global(.swiper-pagination-bullets) {
    left: 0;
    width: 50%;
  }

  :global(.swiper-nav) {
    &:global(.swiper-nav--prev) {
      left: 0;

      @include mixins.rtl {
        right: 0;
        left: unset;
      }
    }

    &:global(.swiper-nav--next) {
      right: 50% !important;

      @include mixins.rtl {
        right: unset !important;
        left: 50% !important;

        @include mixins.max-sm {
          left: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .swiper-container--pov-modal {
    :global(.swiper-slide) {
      flex-direction: column;
    }

    :global(.swiper-pagination-bullets) {
      top: calc(60vh - 30px);
      bottom: unset;
      width: 100%;
    }

    :global(.swiper-nav) {
      height: 100%;
      max-height: 60vh;

      &:global(.swiper-nav--next) {
        right: 0 !important;
      }
    }

    .pov-info,
    .pov-media {
      width: 100%;
    }

    .pov-media {
      height: 100%;
      max-height: 60vh;
    }
  }
}
