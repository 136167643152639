.event__media {
  border-radius: 8px;
  align-self: flex-start;

  &:hover {
    & + .event__info .event__name a {
      color: var(--success-main);
    }
  }

  :global(.swiper-container--gallery) {
    width: 100%;
    height: 100%;
  }

  :global(.image-placeholder),
  video,
  img {
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
}
