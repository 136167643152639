@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;

@mixin swiper-circle-controls() {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mixins.clamp(width, 32px, 60px);
  @include mixins.clamp(height, 32px, 60px);
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.12);
  z-index: 1;
  transform: translate(50%, -50%);
  cursor: pointer;

  svg {
    @include mixins.clamp(width, 12px, 20px);
    @include mixins.clamp(height, 12px, 20px);
  }

  &:hover {
    box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.3),
      0px 0px 2px rgba(0, 0, 0, 0.3);
    color: var(--primary-main);

    svg {
      path {
        fill: var(--primary-main);
      }
    }
  }

  @include mixins.rtl {
    transform: translate(-50%, -50%);
  }

  &.swiper-nav--prev {
    transform: translate(-50%, -50%);

    @include mixins.rtl {
      transform: translate(50%, -50%);
    }
  }
}

.swiper-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;

  &--fullscreen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    max-height: 100vh;
    height: 100% !important;
    z-index: 1102;

    :global(.swiper-slide) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .swiper-nav {
      &.swiper-nav--prev {
        @include mixins.clamp(left, 24px, 70px);

        @include mixins.rtl {
          @include mixins.clamp(right, 24px, 70px);
          left: unset;
        }
      }

      &.swiper-nav--next {
        @include mixins.clamp(right, 24px, 70px);

        @include mixins.rtl {
          right: unset;
          @include mixins.clamp(left, 24px, 70px);
        }
      }
    }
  }

  &--gallery {
    .swiper {
      border-radius: 8px;

      :global(.swiper-slide) {
        aspect-ratio: 16 / 9;
        border-radius: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 8px;
        }
      }
    }
  }
}

.swiper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  z-index: 3;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @include mixins.rtl {
    right: 0;
    left: unset;

    &::after {
      transform: rotate(180deg);
    }
  }

  &--prev {
    &::after {
      content: "prev";
    }

    @include mixins.rtl {
      left: unset;
      right: 0;
    }
  }

  &--next {
    left: unset;
    right: 0;

    &::after {
      content: "next";
    }

    @include mixins.rtl {
      left: 0;
      right: unset;
    }
  }

  &--is-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: swiper-icons;
    font-variant: normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none !important;
    background: transparent;
    transform: none;

    @include mixins.clamp(font-size, 12px, 20px);
  }

  &--rectangle {
    display: flex;
    align-items: center;
    top: 0;
    height: 100%;
    padding: 15px;
    color: #fff;
    background: none;
    box-shadow: none;

    &.swiper-nav--prev {
      left: 0;
      border-radius: 12px 0 0 12px;

      @include mixins.rtl {
        left: unset;
        right: 0;
      }
    }

    &.swiper-nav--next {
      right: 0;
      border-radius: 0 12px 12px 0;

      @include mixins.rtl {
        left: 0;
        right: unset;
      }
    }
  }

  &--circle {
    @include swiper-circle-controls();

    top: 50%;
  }
}

.swiper {
  position: relative;
  width: 100%;
  height: 100%;

  &.swiper-tabs {
    height: auto;

    .swiper-slide {
      width: auto;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.swiper-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 1px;
    background-color: var(--text-hint);
  }

  .swiper-tab {
    width: auto;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--text-hint);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    white-space: nowrap;

    &:hover,
    &:global(.swiper-slide-thumb-active) {
      color: var(--primary-main);
      border-color: var(--primary-main);
    }

    &:global(.swiper-slide-thumb-active)::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--primary-main);
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  &.swiper-tabs--rounded {
    padding: 5px;
    margin: -5px;

    &::after {
      display: none;
    }

    .swiper-tab {
      width: auto !important;
      padding: 9px 16px;
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 24px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06),
        0px 2px 4px 0px rgba(0, 0, 0, 0.04);
      font-weight: 500;

      &:hover,
      &:global(.swiper-slide-thumb-active) {
        color: var(--primary-main);
        border-color: var(--primary-main);
      }

      &:global(.swiper-slide-thumb-active)::after {
        content: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .swiper-container {
    &--fullscreen {
      .swiper-nav {
        opacity: 0.7;
      }
    }
  }
}
