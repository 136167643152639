@use "@/styles/mixins" as mixins;

.event-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include mixins.clamp(gap, 20px, 24px);

  &--compact {
    flex-direction: row;

    @include mixins.max-sm {
      display: grid;
      grid-template-rows: repeat(var(--rows, 1), 1fr);
      grid-auto-flow: column;
      grid-auto-columns: 100%;
      grid-gap: 16px 0;

      @include mixins.clamp(padding-right, 20px, 40px);
      @include mixins.clamp(padding-left, 20px, 40px);

      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scrollbar-width: none;
      -ms-overflow-style: none;

      @include mixins.clamp(scroll-padding-left, 20px, 40px);

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &--compare {
    & > :global(.swiper) {
      padding-top: 5px;
      @include mixins.clamp(padding-right, 20px, 40px);
      padding-bottom: 5px;
      @include mixins.clamp(padding-left, 20px, 40px);
      margin-top: -5px;
      margin-bottom: -5px;
      flex: 1;

      :global(.swiper-wrapper) {
        height: unset;

        :global(.swiper-slide) {
          height: unset;
          max-width: 470px;
          margin-right: 20px;

          @include mixins.rtl {
            margin-right: unset;
            margin-left: 20px;
          }
        }
      }

      &:global(.swiper--is-locked) {
        :global(.swiper-wrapper) {
          justify-content: center;
        }
      }

      @include mixins.sm {
        padding: 5px;
      }
    }
  }
}
