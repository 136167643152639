@use "@/styles/utilities" as utilities;
@use "@/styles/mixins" as mixins;

.reviews {
  &__summary {
    display: flex;
    flex-direction: row;
    gap: 16px;

    &-score {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .reviews__average-score {
        text-align: center;
        padding: 9px;
        width: 44px;
        height: 40px;
        background: var(--success-main);
        border-radius: 6px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
      }

      .reviews__average-rating {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .reviews__average-rating-name {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }

        .reviews__average-rating-star {
          display: inline-block;
          width: 60px;
          height: 12px;
          background: url(../../../../public/images/icons/star.svg) repeat-x;
          background-size: 12px;
          vertical-align: top;

          .reviews__star-filled {
            display: inherit;
            height: inherit;
            background: url(../../../../public/images/icons/star_filled.svg)
              repeat-x;
            background-size: inherit;
            vertical-align: inherit;
          }
        }
      }
    }

    &-text {
      border-left: 1px solid var(--text-hint);
      padding-left: 16px;

      @include mixins.rtl {
        border-right: 1px solid var(--text-hint);
        border-left: unset;
        padding-right: 16px;
        padding-left: unset;
      }

      &-title {
        display: flex;
        flex-direction: row;

        &,
        * {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }

        .reviews__summary-count {
          border-left: 1px solid var(--text-hint);
          margin-left: 16px;
          padding-left: 16px;

          @include mixins.rtl {
            border-right: 1px solid var(--text-hint);
            border-left: unset;
            padding-right: 16px;
            padding-left: unset;
            margin-right: 16px;
            margin-left: unset;
          }
        }
      }

      &-description {
        margin-top: 2px;
        font-size: 13px;
        line-height: 16px;
        color: var(--success-main);
      }
    }
  }

  &__list-title {
    margin-top: 32px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    .reviews__list-count {
      color: var(--text-secondary);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0;
    margin-top: 24px;
  }
}

.review {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    :global(.image-placeholder),
    img,
    svg {
      width: 32px;
      height: 32px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }

    svg {
      background: #c4c4c4;
      padding: 5px;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  &__date {
    margin-left: auto;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-secondary);

    @include mixins.rtl {
      margin-right: auto;
      margin-left: unset;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 44px;
    border-radius: 8px;
    background: #fafafa;
    padding: 16px 20px;

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      width: 20px;
      height: 20px;
      background: inherit;
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);

      @include mixins.rtl {
        right: 0;
        left: unset;
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
      }
    }
  }

  &__info-score {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .review__average-score {
      width: 32px;
      height: 23px;
      padding: 3px 5.5px;
      text-align: center;
      background: var(--success-main);
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }

    .review__average-rating-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: var(--success-dark);
    }

    .review__average-rating-star {
      display: inline-block;
      width: 60px;
      height: 12px;
      background: url(../../../../public/images/icons/star.svg) repeat-x;
      background-size: 12px;
      vertical-align: top;

      .review__star-filled {
        display: inherit;
        height: inherit;
        background: url(../../../../public/images/icons/star_filled.svg)
          repeat-x;
        background-size: inherit;
        vertical-align: inherit;
      }
    }
  }

  &__info-text {
    font-size: 16px;
    line-height: 19px;
  }

  &__gallery {
    margin-top: 8px;
    margin-left: 44px;

    :global(.swiper-container--gallery) {
      height: 80px;
      height: utilities.get-clamp(80px, 112px);

      :global(.swiper-slide) {
        width: auto;
        border-radius: 8px;
        aspect-ratio: unset;

        :global(.image-placeholder),
        img,
        video {
          width: auto;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .reviews {
    &__summary {
      flex-wrap: wrap;
      border-bottom: 1px solid var(--text-hint);
      padding-bottom: 20px;

      &-score {
        width: 100%;

        .reviews__average-rating {
          .reviews__average-rating-name {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      &-text {
        padding-left: 0;
        border-left: none;

        @include mixins.rtl {
          padding-right: 0;
          border-right: none;
        }

        &-title {
          display: flex;
          flex-direction: row;

          &,
          * {
            font-size: 16px;
            line-height: 19px;
          }

          .reviews__summary-count {
            margin-left: 12px;
            padding-left: 12px;

            @include mixins.rtl {
              padding-right: 12px;
              padding-left: unset;
              margin-right: 12px;
              margin-left: unset;
            }
          }
        }

        &-description {
          margin-top: 4px;
        }
      }
    }

    &__list-title {
      margin-top: 20px;
      font-size: 20px;
      line-height: 24px;
    }

    &__list {
      :global(.swiper-slide) {
        gap: 18px;
      }

      .swiper-pagination-pages {
        margin-top: 24px;
        margin-left: 0;
      }
    }
  }

  .review {
    gap: 10px;

    &__head {
      :global(.image-placeholder),
      img,
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__name {
      font-size: 16px;
      line-height: 19px;
    }

    &__date {
      font-size: 14px;
      line-height: 17px;
    }

    &__content {
      padding: 12px 16px;
    }

    &__info-score {
      .review__average-score {
        width: 28px;
        height: 20px;
        padding: 3px 4px;
        font-size: 12px;
        line-height: 15px;
      }

      .review__average-rating-name {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__gallery {
      margin-top: 4px;
    }
  }
}

body.rtl
  .review
  .review__content
  .review__info-score
  .review__average-rating-star
  .review__star-filled {
  transform: rotateY(180deg);
}
