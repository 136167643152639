@use "@/styles/mixins" as mixins;

.step-list {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 16px;

  &--vertical {
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &-tail {
      position: absolute;
      top: 24px;
      left: 10px;
      width: 1px;
      height: calc(100% - 12px);
      background-color: var(--text-disabled);

      &:last-child {
        display: none;
      }
    }

    &-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      aspect-ratio: 1 / 1;
      @include mixins.clamp(margin-right, 12px, 16px);

      & > * {
        width: 100%;
        height: 100%;
      }
    }

    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--primary-pale);
      color: var(--primary-main);
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-title {
      @include mixins.clamp(font-size, 16px, 18px);
      @include mixins.clamp(line-height, 20px, 22px);
      font-weight: 600;
    }

    &-description {
      @include mixins.clamp(font-size, 14px, 16px);
      @include mixins.clamp(line-height, 18px, 20px);
      color: var(--text-secondary);
    }

    &:hover {
      .step-list__item-icon {
        background-color: var(--success-main);
        color: #fff;
      }

      .step-list__item-title {
        color: var(--success-main);
      }
    }
  }
}
