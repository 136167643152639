@use "@/styles/mixins" as mixins;

.buttons {
  .hidden-scrollbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 16px;
    margin-top: -5px;
    margin-bottom: -5px;
    padding-top: 5px;
    padding-bottom: 5px;

    @include mixins.clamp(margin-right, -50px, -20px);
    @include mixins.clamp(margin-left, -50px, -20px);
    @include mixins.clamp(padding-right, 20px, 50px);
    @include mixins.clamp(padding-left, 20px, 50px);

    :global(.button) {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      padding: 10px 16px;
      border-radius: 12px;
      border-width: 1px;
      font-size: 16px;
      font-weight: 400;
    }

    .show-more {
      display: none;

      .selected {
        border-color: var(--primary-main);
        color: var(--primary-main);
      }
    }

    &.opened {
      &::after {
        content: "";
        flex: 1;
      }
    }

    &.overflow {
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
}
