.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateZ(0);
  transition: opacity 0.3s;
  z-index: 10;
}

.swiper-pagination .swiper-pagination-bullet {
  background: var(--swiper-pagination-bullet-inactive-color, #fff);
  border-radius: 50%;
  display: inline-block;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.5);
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);

  &.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
  }
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
}

@media (max-width: 767px) {
  .full-width-sm .swiper-nav.swiper-nav--prev {
    left: 20px;
    left: clamp(1.25rem, 0.5682rem + 3.4091vw, 3.125rem);
  }

  .full-width-sm .swiper-nav.swiper-nav--next {
    right: 20px;
    right: clamp(1.25rem, 0.5682rem + 3.4091vw, 3.125rem);
  }

  [dir="rtl"] {
    .full-width-sm .swiper-nav.swiper-nav--prev {
      right: 20px;
      right: clamp(1.25rem, 0.5682rem + 3.4091vw, 3.125rem);
      left: unset;
    }

    .full-width-sm .swiper-nav.swiper-nav--next {
      right: unset;
      left: 20px;
      left: clamp(1.25rem, 0.5682rem + 3.4091vw, 3.125rem);
    }
  }
}
