.event__excerpt {
  overflow-wrap: break-word;
  overflow-wrap: anywhere;

  * {
    font-size: 16px;
    line-height: 20px;
  }

  a,
  :global(.read-more) {
    cursor: pointer;
    color: var(--primary-main);

    &:hover {
      color: var(--primary-dark);
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      position: relative;
      padding-left: 16px;
      margin-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.6em;
        width: 8px;
        height: 1px;
        background-color: currentColor;
      }
    }
  }
}
