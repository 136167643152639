.compare-field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  * {
    font-size: 14px;
    line-height: 18px;
  }

  &__title {
    font-weight: 600;
  }

  ul {
    li {
      position: relative;
      padding-left: 12px;

      &::before {
        content: "\2022";
        position: absolute;
        left: 0;
        color: var(--text-disabled);
        font-weight: bold;
        display: inline-block;
        width: 4px;
      }

      & + li {
        margin-top: 4px;
      }
    }
  }

  &--text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
  }
}
