@use "@/styles/mixins" as mixins;

.event__popularity {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  @include mixins.clamp(gap, 8px, 12px);
  // padding-top: 16px;
  // margin-top: 16px;
  // border-top: 1px solid var(--text-hint);

  :global(.accelerator-count-favorite) {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
