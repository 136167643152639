@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;

.banner {
  position: relative;
  background: linear-gradient(261.91deg, #f8c7e4 -4.87%, #95abf9 87.56%);
  padding: 22px 22px;
  padding: utilities.get-clamp(22px, 56px) utilities.get-clamp(22px, 150px)
    utilities.get-clamp(22px, 77px);
  border-radius: 12px;
  color: #fff;
  overflow: hidden;

  :global(.block__title) {
    @include mixins.clamp(font-size, 24px, 40px);
    @include mixins.clamp(line-height, 29px, 49px);
    @include mixins.clamp(margin-bottom, 12px, 24px);
  }

  .banner__text {
    // max-width: 50%;
    margin-bottom: 24px;

    @include mixins.clamp(font-size, 14px, 24px);
    @include mixins.clamp(line-height, 18px, 22px);
  }

  & > :global(.image-placeholder),
  & > img {
    position: absolute;
    @include mixins.clamp(right, -20px, 170px);
    @include mixins.clamp(top, 105px, -40px);
    @include mixins.clamp(width, 135px, 320px);
    min-height: 100%;
    object-fit: cover;
    object-position: center;

    @include mixins.rtl {
      right: unset;
      @include mixins.clamp(left, -20px, 170px);
    }
  }

  :global(.button-list) {
    max-width: 70%;

    :global(.button) {
      gap: 6px;
      border-radius: 12px;
      padding: 9px;
      @include mixins.clamp(width, 170px, 200px);

      &:hover {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08),
          0px 0px 4px rgba(0, 0, 0, 0.06);
      }

      :global(.button__text-wrapper) {
        font-weight: 600;
      }

      :global(.button__text) {
        font-size: 16px;
        line-height: 16px;
      }

      :global(.button__text--additional) {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }
}

.banner_popup {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #1f1930;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    :global(.block__title) {
      color: #fff;
      font-size: 17px;
      line-height: 17px;
      font-weight: 400;
      margin: 0;
      flex: 1;
    }

    :global(.button-list) {
      width: auto;
    }
  }
}

@media screen and (max-width: 576px) {
  .banner_popup {
    .container {
      flex-direction: column;

      :global(.button-list) {
        :global(.button) {
          width: 100%;
        }
      }
    }
  }
}
