@use "@/styles/mixins" as mixins;

.go-to {
  display: inline-flex;
  position: relative;
  cursor: pointer;

  svg {
    display: block;
    margin: auto;
  }

  &__tooltip {
    position: absolute;
    top: -50%;
    left: -50%;
    z-index: 1;
    padding: 12px;
    background: #fafafa;
    box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    visibility: hidden;
    opacity: 0;

    @include mixins.rtl {
      right: -50%;
      left: unset;
    }
  }

  &:hover {
    color: var(--primary-main);

    .go-to__tooltip {
      visibility: visible;
      opacity: 1;
      color: var(--text-primary);
    }
  }
}
