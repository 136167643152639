@use "@/styles/mixins" as mixins;

.block__title {
  margin: 0;
  font-weight: 700;

  @include mixins.clamp(margin-bottom, 24px, 40px);
  @include mixins.clamp(font-size, 24px, 48px);
  @include mixins.clamp(line-height, 29px, 58px);
}
