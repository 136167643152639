.event__people {
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
  scrollbar-width: none;
  overflow: scroll;
  box-shadow: inset -10px 0 10px -5px #fff;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }
}
