@use "@/styles/utilities" as utilities;
@use "@/styles/mixins" as mixins;

.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mixins.clamp(gap, 12px, 24px);
  color: #fff;
  background: #c4c4c4;
  background-image: url(../../../../public/images/unsplash.webp);
  background-image: linear-gradient(
      260.49deg,
      rgba(248, 201, 229, 0.88) -66.99%,
      rgba(149, 171, 249, 0.88) 75.14%
    ),
    linear-gradient(
      90deg,
      rgba(11, 30, 117, 0.88) 48.52%,
      rgba(11, 31, 117, 0) 100%
    ),
    url(../../../../public/images/unsplash.webp);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 24px 24px;
  padding: utilities.get-clamp(20px, 48px) utilities.get-clamp(24px, 48px)
    utilities.get-clamp(24px, 56px);
  border-radius: 24.4138px;

  * {
    max-width: 727px;
  }

  :global(.block__title) {
    margin: 0;
    font-weight: 700;
    @include mixins.clamp(font-size, 20px, 36px);
    @include mixins.clamp(line-height, 24px, 44px);
  }

  .text {
    @include mixins.clamp(font-size, 14px, 19.531px);
    @include mixins.clamp(line-height, 18px, 29px);
    text-align: center;
  }

  :global(.block__title),
  .text {
    & + .subscribe-form {
      @include mixins.clamp(margin-top, 12px, 16px);
    }
  }

  .subscribe-form {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: 18px;

    .input-container {
      border: 5px solid rgba(255, 255, 255, 0.14);
      border-radius: 18px;
      width: 100%;
    }

    input {
      position: relative;
      width: inherit;
      padding: 14.5px 24px;
      border: none;
      border-radius: 14.6483px;
      @include mixins.clamp(font-size, 16px, 21.9724px);
      @include mixins.clamp(line-height, 20px, 27px);
      color: var(--text-secondary);

      &:focus {
        border: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 6px;
      background-color: #7e7dc8;
      border: none;
      border-radius: 13.4276px;
      color: #fff;
      height: calc(100% - 12px);
      padding: 12px 12.5px;
      padding: utilities.get-clamp(12px, 12px)
        utilities.get-clamp(12.5px, 62.33px);
      @include mixins.clamp(font-size, 18px, 21.9724px);
      line-height: 22px;
      // transition: colors.$transition25;

      span {
        position: relative;
        z-index: 2;
      }

      &:hover {
        background-color: #6a6aa6;
      }

      &.success,
      &.error {
        overflow: hidden;
        animation: width-full 0.25s both;
        animation-direction: alternate;

        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 20px;
          height: 20px;
          background: linear-gradient(
            271.82deg,
            #0066ff -0.75%,
            #b81ad6 99.37%
          );
          top: 50%;
          border-radius: 50%;
          z-index: 1;
        }

        &::before {
          transform: translate(-50%, -50%);
          left: -20px;
          animation: move-left 0.5s both;
          animation-direction: alternate;
          animation-delay: 0.25s;
        }

        &::after {
          transform: translate(50%, -50%);
          right: -20px;
          animation: move-right 0.5s both;
          animation-direction: alternate;
          animation-delay: 0.25s;
        }
      }

      &.error {
        &::before,
        &::after {
          background: linear-gradient(
            271.82deg,
            #d7816a -0.75%,
            #bd4f6c 99.37%
          );
        }
      }

      &.success-remove {
        animation-direction: alternate;
        animation: width-unset 0.7s both;
      }

      @include mixins.rtl {
        right: unset;
        left: 6px;
      }
    }
  }
}

@keyframes width-full {
  from {
    @include mixins.clamp(width, 116px, 241.66px);
  }
  to {
    width: calc(100% - 12px);
  }
}

@keyframes move-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    height: 1000px;
    width: 1000px;
  }
}

@keyframes move-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    height: 1000px;
    width: 1000px;
  }
}

@keyframes width-unset {
  from {
    width: 100%;
  }
  to {
    @include mixins.clamp(width, 116px, 241.66px);
  }
}

@media screen and (max-width: 425px) {
  .subscribe {
    .subscribe-form {
      flex-direction: column;

      button {
        position: relative;
        margin-top: 20px;
        width: 100%;
        right: unset;

        &.success,
        &.error,
        &.success-remove {
          animation: unset;
        }
      }
    }
  }
}
