.button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;

  :global(.button) {
    height: unset;
  }
}
